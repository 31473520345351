<template>
  <div class="taskList">
    <div class="person-header">
      <strong>项目列表</strong>
      <el-button
        type="primary"
        icon="el-icon-upload"
        style="background-color: #00ace6; border-radius: 20px !important"
        @click="$router.push('/task/add')"
        size="small"
        round
        >发布任务
      </el-button>
    </div>
    <div class="tasklist-lists">
      <div class="mine-service-bottom">
        <div class="mine-service-bottom-header">
          <el-tabs v-model="activeName" @tab-click="handleClick" stretch>
            <el-tab-pane
              :label="item.title"
              :name="item.name"
              v-for="(item, index) in tabList"
              :key="item.name"
            ></el-tab-pane>
          </el-tabs>
          <div class="mine-service-bottom-header-right">
            <i class="iconfont icon-search wh-16 b"></i>
            <input
              v-model="searchData.kw"
              type="text"
              @keydown.enter="loadTaskList()"
              placeholder="搜索"
            />
          </div>
        </div>
        <div class="taskListBox" v-loading="listLoading">
          <div class="hirerProject">
            <div class="tasklist-admin-bottom-no-data" v-show="isEmpty">
              <div class="no-data">
                <img src="@/assets/banner/不公开页面.png" style="width: 180px; height: auto" />
                <p style="text-align: center; color: #9ca3af">还没有任务！</p>
                <p>
                  <el-button
                    type="primary"
                    icon="el-icon-upload"
                    style="background-color: #00ace6; border-radius: 20px !important"
                    round
                    @click="$router.push('/task/add')"
                    >发布任务
                  </el-button>
                </p>
              </div>
            </div>
            <div class="hirerProjects" v-for="(task, index) in taskList" :key="index">
              <div class="hirerProject-center">
                <div class="hirerProject-center-left">
                  <div class="hirerProject-center-left-header">
                    <div class="hirerProject-center-left-header-left">
                      <div v-if="task.certification_type == 0">
                        <i class="iconfont icon-tips r wh-16"></i>
                        <span class="redp">请为该项目设置主体</span>
                      </div>
                      <div v-else>
                        <i
                          v-if="task.certification_type == 1"
                          class="iconfont icon-personal wh-16 b"
                        ></i>
                        <i
                          v-if="task.certification_type == 2"
                          class="iconfont icon-hirer wh-16 b"
                        ></i>
                        <span class="f-16 h-57"
                          ><span style="font-size: 12px; margin-left: 0px">{{
                            task.certificaion_info.cert_name
                          }}</span></span
                        >
                      </div>
                    </div>
                    <div class="hirerProject-center-left-header-right">
                      <el-tooltip
                        v-if="task.show_type == 1"
                        class="item"
                        effect="dark"
                        content="前往任务页"
                        placement="top-end"
                      >
                        <span class="f-12 h-57" @click="jumpToFront(task)" style="cursor: pointer"
                          >项目编号：{{ task.sn }} <i class="el-icon-link b"></i
                        ></span>
                      </el-tooltip>

                      <el-tooltip
                        v-else
                        class="item"
                        effect="dark"
                        content="内部任务只对被邀请人开放"
                        placement="top-end"
                      >
                        <span class="f-12 h-57" style="cursor: pointer"
                          >项目编号：{{ task.sn }} <i class="el-icon-link b"></i
                        ></span>
                      </el-tooltip>
                    </div>
                  </div>
                  <p
                    v-if="task.show_type == 1"
                    @click="jumpToFront(task)"
                    style="cursor: pointer"
                    >{{ task.subject }}</p
                  >
                  <p v-else style="cursor: pointer">{{ task.subject }}</p>
                  <ul>
                    <li v-if="task.pay_way == 1">
                      <el-button type="text">
                        <i class="iconfont icon-pay wh-14 b"></i>
                        预算{{ task.budget_amount }}
                      </el-button>
                    </li>
                    <li v-if="task.pay_way == 2">
                      <el-button type="text">
                        <i class="iconfont icon-pay wh-14 b"></i>
                        时薪{{ task.budget_amount }}
                      </el-button>
                    </li>

                    <!--固定薪酬-->
                    <li v-if="task.pay_way == 1">
                      <el-button type="text">
                        <i class="iconfont icon-definite b wh-14"></i>
                        {{ task.pay_way_name }}
                      </el-button>
                    </li>
                    <!--时薪-->
                    <li v-if="task.pay_way == 2">
                      <el-button type="text">
                        <i class="iconfont icon-hour b wh-14"></i>
                        {{ task.pay_way_name }}
                      </el-button>
                    </li>

                    <!--支付周期 周、月、一次性、分期-->
                    <li v-if="task.pay_cycle == 1">
                      <el-button type="text">
                        <i class="iconfont icon-week b wh-14"></i>
                        {{ task.pay_cycle_name }}
                      </el-button>
                    </li>
                    <li v-if="task.pay_cycle == 2">
                      <el-button type="text">
                        <i class="iconfont icon-month b wh-14"></i>
                        {{ task.pay_cycle_name }}
                      </el-button>
                    </li>
                    <li v-if="task.pay_cycle == 3">
                      <el-button type="text">
                        <i class="iconfont icon-whole b wh-14"></i>
                        {{ task.pay_cycle_name }}
                      </el-button>
                    </li>
                    <li v-if="task.pay_cycle == 4">
                      <el-button type="text">
                        <i class="iconfont icon-installment b wh-14"></i>
                        {{ task.pay_cycle_name }}
                      </el-button>
                    </li>

                    <li>
                      <el-button type="text">
                        <i class="iconfont icon-personal b wh-14"></i>
                        雇佣 {{ task.plan_hire_count }} 人
                      </el-button>
                    </li>

                    <li>
                      <el-button type="text">
                        <i class="iconfont icon-website b wh-14"></i>
                        {{ task.pay_channel_name }}
                      </el-button>
                    </li>

                    <li v-if="task.show_type == 2">
                      <el-button type="text" style="color: #d4237a">
                        <i class="el-icon-set-up r wh-16"></i>
                        内部定向任务
                      </el-button>
                    </li>

                    <li v-show="false">
                      <el-button type="text">
                        <i class="iconfont icon-time b wh-14"></i>
                        立即发布
                      </el-button>
                    </li>

                    <li v-if="task.work_location == 2">
                      <el-button type="text">
                        <i class="iconfont icon-address b wh-14"></i>
                        工作地点 {{ task.work_region_name }}
                      </el-button>
                    </li>

                    <li v-show="task.audit_status == 1">
                      <el-button type="text">
                        <i class="iconfont icon-time b wh-14"></i>
                        提交时间 {{ task.created_at }}
                      </el-button>
                    </li>
                  </ul>
                </div>
                <div class="hirerProject-center-right">
                  <!-- <button class="primary">立即发布</button> -->
                  <!--                  <el-button size="mini" type="danger" icon="el-icon-setting"-->
                  <!--                             v-show="task.audit_status != 9 && task.certification_id == 0"-->
                  <!--                             @click="jumpToCertification(task)">设置发布主体-->
                  <!--                  </el-button>-->
                  <!--修改-->
                  <el-button
                    size="mini"
                    type="primary"
                    icon="el-icon-edit-outline"
                    v-show="task.audit_status != 9 || task.status <= 5"
                    @click="jumptToUpdate(task.sn)"
                    >修改
                  </el-button>
                  <!--未开标-->
                  <el-button
                    size="mini"
                    type="primary"
                    v-show="task.audit_status == 9 && task.status == 1"
                    @click="startBid(task)"
                    >提前开标
                  </el-button>
                  <!--工作中-->
                  <el-button
                    size="mini"
                    type="success"
                    icon="el-icon-monitor"
                    :disabled="
                      !([2, 3, 5, 7, 8, 9].includes(task.status) && task.audit_status == 9)
                    "
                    @click="jumpToWorkstation(task.sn)"
                    >工作管理
                  </el-button>
                  <!--流标-->
                  <el-button
                    size="mini"
                    type="primary"
                    icon="el-icon-refresh-left"
                    v-show="task.audit_status == 9 && task.status == 4"
                    @click="jumptToUpdate(task.sn)"
                    >重新开标
                  </el-button>

                  <!--竞标中 和 选标期-->
                  <!--										<button v-show="task.audit_status == 9 && (task.status == 3 || task.status == 5)" @click="jumpToWorkstation(task.sn)" class="primary">去选标</button>-->
                  <!--资金托管-->
                  <!--										<button v-show="task.audit_status == 9 && task.status == 7" @click="jumpToWorkstation(task.sn)" class="primary">资金托管</button>-->
                  <!--工作结束-->
                  <!--                    <button v-show="task.audit_status == 9 && task.status == 9" @click="jumpToWorkstation(task.sn)" class="primary">工作管理</button>-->

                  <el-dropdown trigger="hover">
                    <span class="el-dropdown-link">更多操作</span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        v-show="task.audit_status != 9 || task.certification_type == 0"
                      >
                        <el-button
                          type="text"
                          icon="el-icon-refresh"
                          @click="jumpToCertification(task)"
                          >更换发布主体
                        </el-button>
                      </el-dropdown-item>
                      <!--未开标 可提前开标-->
                      <!--                      <el-dropdown-item :class="!(task.status == 1 && task.audit_status == 9)?'':'b'" icon="el-icon-finished" :disabled="!(task.status == 1 && task.audit_status == 9)">-->
                      <!--                        <span @click="startBid(task)"> 提前开标</span>-->
                      <!--                      </el-dropdown-item>-->

                      <!--竞标阶段 可提前结束竞标-->
                      <el-dropdown-item
                        :disabled="!(task.status == 3 && task.audit_status == 9)"
                        icon="el-icon-video-pause"
                      >
                        <span @click="endBid(task)">结束招标</span>
                      </el-dropdown-item>

                      <!--删除-->
                      <el-dropdown-item
                        :class="task.status >= 8 ? '' : 'r'"
                        icon="el-icon-delete"
                        :disabled="task.status >= 8"
                      >
                        <span @click="deleteOneTask(task)">删除</span>
                      </el-dropdown-item>

                      <!--资金托管期-->
                      <!-- <el-dropdown-item v-show="task.audit_status == 9 && task.status == 7">资金托管</el-dropdown-item> -->

                      <!-- <el-dropdown-item v-show="task.audit_status != 9">资金托管方式</el-dropdown-item> -->
                      <!--<el-dropdown-item v-show="task.audit_status == 9 && task.status < 8 && task.status != 2">关闭</el-dropdown-item>-->

                      <!--选标阶段 可提前结束选标-->
                      <!--                      <el-dropdown-item v-show="task.status == 5 && task.audit_status == 9 ">-->
                      <!--                        <el-button type="text" icon="el-icon-video-pause" @click="endSelectBid(task)">结束选标</el-button>-->
                      <!--                      </el-dropdown-item>-->
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
              <div class="hirerProject-bottom">
                <!--等待审核-->
                <p v-if="task.audit_status == 1">
                  <i class="iconfont icon-wait y"></i>
                  <strong class="yellowp">等待审核</strong>
                  <span class="f-12 h-57" v-show="task.certification_id > 0"
                    >平台将在1个工作日内审核。</span
                  >
                  <span class="f-12 h-57" v-show="task.certification_id == 0"
                    >根据相关法规，发布任务必须为实名认证的个人或者企业，请尽快
                    <b
                      style="cursor: pointer; margin-left: 10px"
                      class="b"
                      @click="jumpToCertification(task)"
                      >前往设置>></b
                    ></span
                  >
                </p>

                <!--审核未通过-->
                <p v-if="task.audit_status == 2">
                  <i class="iconfont icon-delete r wh-16"></i>
                  <strong class="redp">审核未通过</strong>
                  <span class="f-12 h-57" v-if="task.audit_info"
                    >原因：{{ task.audit_info.content }}</span
                  >
                </p>

                <!--任务流程 未开标-->
                <p v-if="task.audit_status == 9 && task.status == 1">
                  <i class="iconfont icon-wait g"></i>
                  <strong class="greenp">等待开标</strong>
                  <span class="f-12 h-57">开标时间：{{ task.bid_start_time }}</span>
                </p>

                <!--任务流程 该标已关闭-->
                <p v-if="task.audit_status == 9 && task.status == 2">
                  <i class="iconfont icon-wait r"></i>
                  <strong class="redp">已关闭</strong>
                  <span class="f-12 h-57">项目长期未响应，已经关闭</span>
                </p>

                <!--任务流程 竞标中-->
                <p v-if="task.audit_status == 9 && task.status == 3">
                  <i class="iconfont icon-success g wh-16"></i>
                  <strong class="greenp f-12 h-57">竞标中</strong>
                  <span class="f-12 h-57">{{ task.bid_start_time }} - {{ task.bid_end_time }}</span>
                  <span class="f-12 h-57">
                    <i class="iconfont icon-staff b"></i>
                    &nbsp;&nbsp;{{ task.bid_count }}人投标
                  </span>
                  <span class="f-12 h-57">
                    <i class="iconfont icon-pass b"></i>
                    &nbsp;&nbsp;已选{{ task.bid_ok_count }}人
                  </span>
                </p>

                <!--任务流程 流标-->
                <p v-if="task.audit_status == 9 && task.status == 4">
                  <i class="iconfont icon-delete r"></i>
                  <strong class="redp">流标</strong>
                  <span class="f-12 h-57">无人投标，该项目流标</span>
                </p>

                <!--任务流程 选标期-->
                <p v-if="task.audit_status == 9 && task.status == 5">
                  <i class="iconfont icon-time g"></i>
                  <strong class="greenp f-12 h-57">选标期</strong>
                  <span class="f-12 h-57">截至{{ task.bid_select_end_time }}</span>
                  <span class="f-12 h-57">
                    <i class="iconfont icon-staff b"></i>
                    &nbsp;&nbsp;{{ task.bid_count }}人投标
                  </span>
                  <span class="f-12 h-57">
                    <i class="iconfont icon-pass b"></i>
                    &nbsp;&nbsp;已选{{ task.bid_ok_count }}人
                  </span>
                </p>

                <!--任务流程 资金准备期-->
                <p v-if="task.audit_status == 9 && task.status == 7 && !task.is_capital_custody">
                  <i class="iconfont icon-wait y"></i>
                  <strong class="yellowp">资金准备期</strong>
                  <span class="f-12 h-57" v-show="task.plan_period_count > 1"
                    >共{{ task.plan_period_count }}期</span
                  >
                  <span class="redbp">请尽快托管资金</span>
                </p>

                <!--任务流程 资金准备期-->
                <p v-if="task.audit_status == 9 && task.status == 7 && task.is_capital_custody">
                  <i class="iconfont icon-wait y"></i>
                  <strong class="yellowp">资金已托管</strong>
                  <span class="f-12 h-57" v-show="task.plan_period_count > 1"
                    >共{{ task.plan_period_count }}期</span
                  >
                  <span class="redbp">请尽快开始任务</span>
                </p>

                <!--工作中-->
                <p v-if="task.audit_status == 9 && task.status == 8">
                  <i class="iconfont icon-wait g"></i>
                  <strong class="greenp">工作中</strong>
                  <!--如果是分期的-->
                  <span v-if="task.plan_period_count > 1" class="f-12 h-57"
                    >共{{ task.plan_period_count }}个工作周期</span
                  >
                </p>

                <div
                  v-if="task.status == 9"
                  style="display: -webkit-flex; display: flex; padding: 0 10px 0 10px; flex: 1"
                >
                  <div style="flex: 0 0 auto">
                    <i class="iconfont icon-success g"></i>
                    <strong style="margin-left: 8px" class="greenp">已完结</strong>
                    <span style="margin-left: 20px" class="f-12 h-57"
                      >该项目于{{ task.finish_time }}完成。</span
                    >
                  </div>
                  <div style="flex: auto; overflow: auto; text-align: right">
                    <span class="f-12 h-57" style="padding-right: 10px">收到的评价：</span>
                    <el-rate
                      v-model="task.evaluate_score"
                      disabled
                      show-score
                      text-color="#ff9900"
                      score-template="{value}"
                    ></el-rate>
                    <span class="f-12 h-57" style="padding-left: 5px"
                      >({{ task.evaluate_count }}个评价)</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.pageSize"
          layout="prev, pager, next,->,total,jumper"
          :total="pagination.total"
          background
          :hide-on-single-page="true"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    loadTaskListOfHirer,
    deleteTask,
    endTaskBid,
    endTaskSelectBid,
    startTaskBid
  } from '@/api/taskuc.js';

  export default {
    data() {
      return {
        isEmpty: false,
        listLoading: false,
        activeName: 'all',
        currentPage: 1,
        tabList: [
          {
            title: '全部项目',
            name: 'all'
          },
          {
            title: '投标中',
            name: 'bid'
          },
          {
            title: '流标',
            name: 'flow'
          },
          {
            title: '工作中',
            name: 'ing'
          },
          {
            title: '已完结',
            name: 'finish'
          }
        ],
        searchData: {
          kw: '',
          status_group: 'all',
          page: 1,
          page_size: 10
        },
        taskList: [],
        pagination: {
          page: 1,
          pageNum: 1,
          pageSize: 10,
          total: 0
        }
      };
    },
    created() {
      this.loadTaskList();
    },
    methods: {
      async loadTaskList() {
        this.listLoading = true;
        const res = await loadTaskListOfHirer(this.searchData);
        if (+res.statusCode === 200) {
          this.taskList = res.data.list;
          this.pagination = res.data.page;

          if (this.pagination.total == 0) {
            this.isEmpty = true;
          } else {
            this.isEmpty = false;
          }
        }
        let _this = this;
        setTimeout(function () {
          _this.listLoading = false;
        }, 800);
      },
      deleteOneTask(task) {
        let _this = this;
        this.$confirm('您确定删除编号为【' + task.sn + '】的任务吗？删除后任务无法找回，请慎重处理')
          .then((_) => {
            deleteTask(task.id, _this.$root.current_token).then((res) => {
              _this.taskList.splice(
                _this.taskList.findIndex((item) => item.id == task.id),
                1
              );
            });
          })
          .catch((_) => {});
      },
      startBid(task) {
        let _this = this;
        this.$confirm('您确定编号为【' + task.sn + '】的任务提前开标吗？')
          .then((_) => {
            startTaskBid(task.id, _this.$root.current_token).then((res) => {
              _this.loadTaskList();
            });
          })
          .catch((_) => {});
      },
      endBid(task) {
        let _this = this;
        this.$confirm(
          '您确定提前结束编号为【' +
            task.sn +
            '】的招标吗？结束后，自由职业者将无法参与竞标。请慎重处理'
        )
          .then((_) => {
            endTaskBid(task.id, _this.$root.current_token).then((res) => {
              _this.loadTaskList();
            });
          })
          .catch((_) => {});
      },
      endSelectBid(task) {
        let _this = this;
        this.$confirm(
          '您确定提前结束编号为【' + task.sn + '】的选标吗？选标结束后,任务将进入工作模式！'
        )
          .then((_) => {
            endTaskSelectBid(task.id, _this.$root.current_token).then((res) => {
              _this.loadTaskList();
            });
          })
          .catch((_) => {});
      },

      jumpToFront(task) {
        window.open('/task/info/' + task.sn, '_blank');
        //this.$router.push('/task/info/' + task.sn );
      },
      jumptToUpdate(taskSN) {
        var url = this.$urlencode('/home/hirer/task/list');
        this.$router.push('/task/update/' + taskSN + '?redirect_url=' + url);
      },
      jumpToCertification(task) {
        //跳转到认证界面
        this.$redirectUrl.jumpTo(
          '/auth/task/' +
            task.sn +
            '?id=' +
            task.certification_id +
            '&type=' +
            task.certification_type
        );
      },
      jumpToWorkstation(taskSN) {
        //跳转到工作管理界面
        this.$router.push('/home/hirer/task/info?sn=' + taskSN);
      },
      handleClick(tab, event) {
        this.searchData.status_group = tab.name;
        this.searchData.page = 1;
        this.searchData.kw = '';
        this.loadTaskList();
      },
      handleCommand(command) {
        this.$message('click on item ' + command);
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.searchData.page_size = val;
        this.loadTaskList();
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.searchData.page = val;
        this.loadTaskList();
      }
    }
  };
</script>

<style lang="less" scoped>
  .tasklist-admin-bottom-no-data {
    width: 860px;
    height: 400px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    .no-data {
      width: 180px;
      margin: 0 auto;
      font-size: 18px;
      color: #575757;
      text-align: center;

      p {
        margin-bottom: 20px;
      }
    }
  }

  .toRegister {
    width: 860px;
  }

  .taskList {
    min-height: 940px;

    .person-header {
      display: flex;
      padding: 0px 40px;
      align-items: center;
      justify-content: space-between;
    }
  }

  .tasklist-back {
    background-color: #f8f8f8;
    height: 60px;
    display: flex;
    align-items: center;

    p {
      height: 24px;
      display: flex;
      align-items: center;
      margin-left: 40px;

      img {
        margin: 0 20px 0 10px;
      }

      span {
        font-size: 12px;
        color: #575757;
      }

      button {
        width: 80px;
        height: 24px;
        background-color: #00a2e6;
        color: #fff;
        font-size: 12px;
        border-radius: 12px;
      }
    }
  }

  img {
    width: 16px;
    height: 16px;
  }

  .taskList {
    background-color: #fff;
  }

  .tasklist-lists {
    background-color: #fff;
    position: relative;
    top: 20px;

    .mine-service-bottom {
      width: 860px;
      margin: 0 auto;

      .mine-service-bottom-header {
        display: flex;
        justify-content: space-between;

        .mine-service-bottom-all-program {
          width: 960px;

          .mine-service-bottom-all-program-center {
            width: 990px;
            display: flex;
            flex-wrap: wrap;
          }
        }

        .mine-service-bottom-header-right {
          height: 30px;

          input {
            width: 200px;
            height: 32px;
            border: 1px solid #b5b5b5;
            text-indent: 10px;

            input[placeholder] {
              color: #474747;
            }
          }

          .icon-search {
            position: relative;
            right: -190px;
            top: 3px;
          }

          /deep/ #tab-first {
            width: 120px;
            text-align: center;
          }
        }
      }
    }
  }

  .hirerProjects {
    width: 860px;
    margin-top: 20px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #e4e7ed;

    .hirerProject-center {
      display: flex;

      .hirerProject-center-left {
        width: 700px;
        border-right: 1px solid #e4e7ed;

        .hirerProject-center-left-header {
          display: flex;
          justify-content: space-between;

          img {
            width: 16px;
            height: 16px;
          }

          .hirerProject-center-left-header-left {
            height: 14px;

            span {
              font-size: 14px;
              margin-left: 10px;
              color: #575757;
            }
          }

          .hirerProject-center-left-header-right {
            margin-right: 40px;
          }
        }

        p {
          font-size: 18px;
          width: 660px;
          margin: 20px 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        ul {
          display: flex;
          width: 660px;
          flex-wrap: wrap;
          // height: 42px;
          li {
            height: 16px;
            margin-right: 20px;
            margin-bottom: 10px;

            /deep/ .el-button {
              font-size: 12px;
              color: #575757;
              padding: 0;
            }
          }
        }
      }

      .hirerProject-center-right {
        width: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        /deep/ .el-button {
          width: 120px;
          height: 26px;
          border-radius: 0;
          margin-bottom: 20px;
          margin-left: unset;
        }

        .el-button--primary {
          background-color: #00ace6 !important;
        }

        .el-button--success {
          background-color: #32c864 !important;
        }

        .el-button--success:disabled {
          background-color: #6ddf93 !important;
        }

        .el-button--danger {
          background-color: #e4007f !important;
        }
      }
    }

    .hirerProject-bottom {
      line-height: 50px;
      background: #f8f8f8;
      margin-top: 10px;
      display: flex;
      border-top: 1px solid #ebeef5;

      p {
        margin-left: 10px;
        display: flex;
        align-items: center;

        strong {
          font-size: 18px;
          margin: 0 0px 0 8px;
        }

        span {
          margin-left: 20px;
        }
      }

      /* .drop {
    margin-top: -120px;
    margin-left: 422px;
  } */

      .yellowp {
        color: #ff9600;
      }

      .redp {
        color: #e4007f;
      }

      .greenp {
        color: #32c864;
      }

      .bluep {
        color: #00a2e6;
      }

      .redbp {
        font-size: 12px;
        color: #e4007f;
      }
    }
  }

  .hirerProjects:hover {
    box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.3);/*盒子阴影*/
    transition: all 0.5s;/*持续时间*/
  }

  .block {
    padding: 20px 50%;
  }

  img {
    width: 16px;
    height: 16px;
  }

  /deep/ .el-tabs {
    width: 620px;
  }

  /deep/ .el-tabs__content {
    width: 860px;
  }

  /deep/ .el-dropdown-link {
    cursor: pointer;
    color: #00a2e6;
    width: 120px;
    text-align: center;
    display: inline-block;
  }

  /deep/ .el-dropdown-menu__item {
    width: 120px;
    text-align: center;
    padding: 0;
  }

  .box .el-tabs__active-bar.is-top {
    text-align: center;
    width: 0px !important;
    /* 不加important  不会生效，important会覆盖行内样式权限级别*/
    padding: 0 50px !important;
    /* 控制位置*/
    left: 8.5%;
    /* 根据实际情设置，尽量居中就行*/
    box-sizing: border-box;
    background-clip: content-box;
  }

  /deep/ .drop {
    position: relative;
    left: 450px;
  }

  // dialog的修改
  .dialog-center-header {
    width: 520px;
    margin: 0 auto;
    border-bottom: 1px solid #b5b5b5;
  }

  /deep/ .el-dialog__header {
    padding: 0;
  }

  /deep/ .dialog-footer {
    button {
      width: 160px;
      height: 36px;
      background-color: #00a2e6;
    }

    .error {
      background-color: #b5b5b5;
    }

    .dialog-button {
      margin-right: 40px;
    }
  }

  /deep/ .el-dialog__body {
    padding: 0;
  }

  /deep/ .el-dialog--center /deep/ .el-dialog__body {
    padding: 0;
  }

  /deep/ .el-rate__text {
    font-size: 12px;
    color: rgb(255, 153, 0);
  }

  /deep/ .el-rate__icon {
    margin-right: 0;
  }
</style>
